<template>
<div class="menu d-flex flex-column p-2 justify-content-between mobMenu">
<div>
  <div class="d-flex justify-content-between topMenu align-items-center">
  <a @click.prevent="$router.go(-1)" href=""><img src="img/close2.svg" alt=""></a>
<!--  <a @click.prevent="changeLocale" class="px-3 lang " href=""><i><img src="img/Flag2.svg" alt=""></i> {{$i18n.t('header.lang')}}  </a>-->
</div>
  <div class="p-4 my-2">
    <router-link :class="{'active': $route.name ==='Online'}" :to="{name: 'Online' }"  >{{$i18n.t('header.link1')}}</router-link>
    <br>
    <span >
{{$i18n.t('header.and')}}&nbsp;
 </span>
    <router-link :class="{'active': $route.name ==='Offline'}" :to="{name: 'Offline'}" > {{$i18n.t('header.link2')}}</router-link>
    <br>
    <router-link :class="{'active': $route.name ==='Technology'}" :to="{name: 'Technology'}" class="mt-3">{{$i18n.t('header.link3')}}</router-link>

  </div>
</div>

  <footer>
    Monnect ⓒ 2010 — 2022
  </footer>

</div>
</template>

<script>
import {changeLocale} from "@/utills/all";
export default {
  name: "mobMenu",
  methods:{
    changeLocale
  }
}
</script>

<style>
.lang{
  font-size: 10px;
}
.menu{
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(118, 196, 79, 1);
  z-index: 1002;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 34px;

}
.topMenu{
  text-transform: none;

}
footer{
  color: rgba(0, 0, 0, 0.2);
  font-size: 10px;
  text-align: center;
}
</style>
