<template>
  <div class="modalForm d-flex align-items-center justify-content-center flex-column">
    <form ref="form" action="contact.php" method="post">
      <div v-show="stepCount===1" class="step1">
        <div class="d-flex justify-content-between">
          <h1>
           {{$i18n.t("form.h1")}}
          </h1>
          <a @click.prevent="$router.go(-1)" href=""><img src="img/close.svg" alt=""></a>
        </div>
        <div>
          <label class="green">
            {{$i18n.t("form.name")}}
            <input
                v-model="name"
                type="text"
                @input="validStep1"
                placeholder="name"
                name="name"
            >
          </label>
          <label class="green">
            {{$i18n.t("form.email")}}
            <input
                v-model="email"
                type="email"
                @input="validStep1"
                placeholder="name@mail.com"
                name="email"
            >
          </label>
          <div class="d-flex justify-content-end">
            <button @click.prevent="stepCount=2" :disabled="!step1Valid">  {{$i18n.t("form.btn1")}}</button>
          </div>
        </div>
      </div>
      <div v-show="stepCount===2" class="step2">
        <div class="d-flex justify-content-between">
          <h1>
            {{$i18n.t("form.h2")}}
          </h1>
          <a @click.prevent="$router.go(-1)" href=""><img src="img/close.svg" alt=""></a>
        </div>
        <div>
          <label class="green">
            {{$i18n.t("form.message")}}
           <textarea
               v-model="message"
               @input="validStep2"
               name="message"
           ></textarea>
          </label>
          <div class="d-flex justify-content-end">
<!--            <button type="submit"-->
<!--                    :disabled="!step2Valid">  {{$i18n.t("form.btn2")}} html-send</button>-->
            <button type="submit"
                    @click.prevent="submit"
                    :disabled="!step2Valid">  {{$i18n.t("form.btn2")}}</button>
          </div>
        </div>
      </div>
      <div v-if="stepCount===3" class="step3 d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between">
          <h1>
            {{$i18n.t("form.h3")}}
          </h1>
          <a @click.prevent="$router.go(-1)" href=""><img src="img/close.svg" alt=""></a>
        </div>
          <div class="d-flex justify-content-end">
            <button @click.prevent="res">{{ $i18n.t("form.btn3") }}</button>
          </div>

      </div>
    </form>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "writeForm",
  data(){
    return{
      stepCount: 1,
      name:"",
      email: "",
      message: "",
      step1Valid: false,
      step2Valid: false
    }
  },
  methods:{
    emailValidate(val){
      let valid = true;
      const step1 = val.split('@');
      if (!step1[1]) valid = false;
      else {
        const step2 = step1[1].split('.');
        if (!step2[1]) valid = false;
        else if (step2[1].length< 2) valid = false
      }
      return valid;
    },
    validStep1(){
      this.step1Valid = true
      if (this.name.length<2) this.step1Valid = false;
      const em = this.emailValidate(this.email);
      if (!em)  this.step1Valid = false;
    },
    validStep2(){
      this.step2Valid = this.message.length >= 5;
    },
    submit(){

      const data = `name=${this.name}&email=${this.email}&message=${this.message}`
      console.log(data);

      axios.post('contact.php', data
      ).then(()=>{
       this.stepCount = 3;
      })
    },
    res(){
          this.name= "";
          this.email = "";
          this.message = "";
          this.stepCount =1;

    }

  }
}
</script>

<style>
.modalForm {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1004;
  background: #fff;
}

.modalForm form {
  padding: 28px 30px;
  width: 580px;
  /*box-shadow: -20px 0 60px rgba(150, 250, 100, 0.4);*/
  box-shadow: -20px 0px 40px 20px rgb(150 250 100 / 40%);
}

.modalForm h1 {
  font-size: 33px;
  margin: 0;
  flex: 0 0 70%;
  max-width: 70%;
  text-align: left;
}
label{
  margin: 35px 0 0 0;
  font-size: 16px;
  text-transform: uppercase;
}
input, textarea{
  width: 100%;
  border: none;
  font-size: 40px;
  margin: 20px 0 0 0;
  padding: 10px 33px;
  box-shadow: inset 0 0  40px  rgba(217, 217, 217, 0.5);
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: 300;
}
textarea{
  background: url("../../public/img/Arrow1.svg") no-repeat 97% 90%;
  box-shadow: inset -10px 10px  30px  rgba(217, 217, 217, 0.5);
}
button{
  margin-top: 50px;
  padding: 7px 35px;
  border:  1px solid rgb(84, 226, 9);
  color: rgb(84, 226, 9);
  background: #fff;
  font-size: 12px;
  font-weight: 300;

}
button[disabled]{
  border:  1px solid #D2D2D2;
  color: #D2D2D2;
}
.step3{
  min-height: 520px;
}
label{
  width: 100%;
}
input::placeholder{
  color: rgba(217, 217, 217, 0.5);
}
@media screen and (max-width: 768px) {
  .modalForm form {
    max-width: 100%;
  }

  .modalForm h1, input, textarea {
    font-size: 20px;
  }
}
</style>
