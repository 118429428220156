<template>
<div class="d-flex bounce">
  <svg class="el1" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="6.5" r="6" stroke="rgb(84, 226, 9)"/>
  </svg>
  <svg class="el2" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="6.5" r="6" stroke="rgb(84, 226, 9)"/>
  </svg>
  <svg class="el3" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="6.5" r="6" stroke="rgb(84, 226, 9)"/>
  </svg>
</div>
</template>

<script>
export default {
  name: "Bounce"
}
</script>

<style scoped>
.bounce:hover circle{
  stroke: rgba(118, 196, 79, 1);
}
.bounce svg{
  margin:5px 5px 0 0;
}
.bounce:hover .el1,
.bounce:hover .el2,
.bounce:hover .el3{
  -webkit-animation: wiggle 0.9s infinite;
  animation: wiggle 0.9s infinite;
}
.bounce:hover .el1{
  animation-delay: 0s;
}
.bounce:hover .el2{
  animation-delay: .3s;
}
.bounce:hover .el3{
  animation-delay: 0.6s;
}
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  85% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  95% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes wiggle {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  85% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  95% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
