<template>
<div class="d-flex phoneForm">

  <form action="">
    вход или регистрация

    ВВЕДИТЕ ВАШ НОМЕР ТЕЛЕФОНА
    <input type="text">
    Нажимая «продолжить», вы соглашаетесь с условиями договора и политикой конфиденциальности
    продолжить
  </form>

</div>
</template>

<script>
export default {
  name: "phoneForm"
}
</script>

<style >
.phoneForm{
  width: 100%;
  height: 90vh;
}
</style>
