<template>
<div class="page position-relative">
  <a href="" @click.prevent="slideArrow" class="scroll_link" :class="{'lastScreen': activeSlide === 4}">
    <svg width="31" height="65" viewBox="0 0 31 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 64.77L31 49.77H0L15.5 64.77Z" fill="rgb(84, 226, 9)"/>
      <path d="M15 50V0" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
    </svg>
  </a>
  <div ref="fullpage2" id="fullpage2">
    <div class="section online1" :class="{'el1': pageCounters.on === 1, 'el2': pageCounters.on === 2, 'el3': pageCounters.on === 3}">
    <div class="container">
      <h1 class="green text-right anim1">

        <span class="d-block text-left anim2"> {{ $i18n.t('online.h1') }}</span>
        {{ $i18n.t('online.h1_1') }} <br> {{ $i18n.t('online.h1_2') }}
      </h1>
      <ul class="d-flex flex-column align-items-end flex-md-row justify-content-end align-items-md-start h-100  ">
        <li class="p-4 pb-0 pb-md-4 mt-5 element anim1"><h2 class="green pb-0 ">
          <a @click.prevent="slide" href="">{{ $i18n.t('online.el1_h') }}</a>
        </h2>
        </li>
        <li class="p-4 pt-2 pt-md-4  mt-0 mt-md-5 element mx-5 anim2">
          <h2 class="green">

            <a @click.prevent="scrollTo('.online3',3)" href=""> {{ $i18n.t('online.el6_h') }}</a>
          </h2>
          <h2 class="green pt-0">
            <a @click.prevent="scrollTo('.online4',4)" href="">{{ $i18n.t('online.el11_h') }}</a>
<!--            {{ $i18n.t('online.el2_h') }}-->
          </h2>

        </li>
      </ul>
    </div>
    </div>
    <div class="section online2">
      <div class="container">

        <ul class="d-flex justify-content-between h-100 custom4">
          <li class="align-self-start element custom2 anim1"> <h3>{{ $i18n.t('online.el1_h') }}</h3></li>
          <li class="d-flex justify-content-start flex-wrap h-100">
          <div class="mx-4 align-self-top mt-5 element anim1">
            <div>
              <img src="img/icons/arcticons_styxbrowser.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el3_h') }}
            </h2>
            <p>{{ $i18n.t('online.el3_p1') }} </p>

          </div>
          <div class="mx-4 align-self-center element custom3 anim2">
            <div>
              <img src="img/icons/arcticons_onay.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el4_h') }}
            </h2>
            <p>{{ $i18n.t('online.el4_p1') }}</p>

          </div>
          <div class="mx-4 align-self-start element mt-5 mt-md-0 anim1">
            <div>
              <img src="img/icons/arcticons_toplogger.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el5_h') }}
            </h2>
            <p>{{ $i18n.t('online.el5_p1') }} </p>
          </div>
          </li>

        </ul>
      </div>
    </div>
    <div class="section online3">
      <div class="container">

        <ul class="d-flex justify-content-between h-100">
          <li class="align-self-start element custom2 anim1"> <h3>{{ $i18n.t('online.el6_h') }}</h3></li>
          <li class="mx-4 element align-self-md-end align-self-start  anim2">
            <div>
              <img src="img/icons/arcticons_remote-mouse.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el7_h') }}
            </h2>
            <p> {{ $i18n.t('online.el7_p1') }} </p>
          </li>
          <li class="mx-4 element align-self-start custom3 anim1">
            <div>
              <img src="img/icons/arcticons_pixel-filter.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el8_h') }}
            </h2>
            <p>{{ $i18n.t('online.el8_p1') }}</p>
          </li>
          <li class="mx-4 element align-self-center  anim2">
            <div>
              <img src="img/icons/arcticons_electron.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el9_h') }}

            </h2>
            <p> {{ $i18n.t('online.el9_p1') }}</p>
          </li>
          <li class="mx-4 element align-self-start custom3 anim1">
            <div>
              <img src="img/icons/arcticons_investing.svg" alt="">
            </div>
            <h2 class="green">
              {{ $i18n.t('online.el10_h') }}

            </h2>
            <p>{{ $i18n.t('online.el10_p1') }}</p>
            <div><router-link :to="{name: 'TechnologyBlock', params: {code: '2'}}"><bounce/></router-link></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="section online4">
      <div class="container">

        <ul class="d-flex justify-content-between h-100">
          <li class="align-self-start element custom2 anim1"> <h3>{{ $i18n.t('online.el11_h') }}  </h3></li>

          <li class="mx-xl-4 align-self-start d-flex custom1">
            <div class="element mx-5 anim2">
              <div class=" mt-5">
                <img src="img/icons/arcticons_pixel-filter.svg" alt="">
              </div>
              <h2 class="green">
                {{ $i18n.t('online.el12_h') }}
              </h2>
              <p> {{ $i18n.t('online.el12_p1') }}</p>
              <div><img src="img/pci.svg" alt=""></div>
            </div>
            <div class="mx-5 element custom3 anim1">
              <div>
                <img src="img/icons/arcticons_investing.svg" alt="">
              </div>
              <h2 class="green">
                {{ $i18n.t('online.el13_h') }}
              </h2>
              <p> {{ $i18n.t('online.el13_p1') }} </p>
              <div><router-link :to="{name: 'TechnologyBlock', params: {code: '4'}}"><bounce/></router-link></div>
            </div>

          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import Bounce from "@/components/bounceEllipse";
import {animateAll, scrollTo} from "@/utills/all";

export default {
  name: "Online",
  props: ['pageCounters'],
  components:{
    Bounce
  },
  data(){
    return{
      activeSlide: 1,
      blockScroll: false,
    }
  },
  methods: {
    scrollTo,
    animateAll,
    slide(){
      const target =  '.online' + ( this.activeSlide===4 ? 1 : this.activeSlide+1)
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
      });
      this.activeSlide = this.activeSlide === 4 ? 1 : this.activeSlide+1
    },
    slideArrow(){
      if (!this.blockScroll){
        this.blockScroll= true;
        this.slide();
        this.animateAll(`.online${this.activeSlide}`);
      }
      this.$emit('slide',this.activeSlide);

      setTimeout(()=>{
        this.blockScroll = false;
      },1500)
    },
  },
  created() {
    this.$emit('slide',this.activeSlide);
  },
  mounted() {
    this.animateAll(`.online${this.activeSlide}`);
    document.querySelector('.online1').scrollIntoView({
      behavior: 'smooth'
    });
    if (window.innerWidth > 1239){

      document.addEventListener('wheel',e=>{
        if (!this.blockScroll) {
          this.blockScroll = true;
          if (e.deltaY > 0 && this.activeSlide !== 4) {
            this.slide();
            this.animateAll(`.online${this.activeSlide}`);
          }
          if (e.deltaY < 0 && this.activeSlide !== 1) {
            document.querySelector(`.online${this.activeSlide - 1}`).scrollIntoView({
              behavior: 'smooth'
            });
            this.activeSlide = this.activeSlide - 1;
            this.animateAll(`.online${this.activeSlide}`);
          }

          this.$emit('slide',this.activeSlide);
          setTimeout(() => {
            this.blockScroll = false;
          }, 1500)
        }
      })
    }

  },
  beforeUnmount() {
    this.$emit('count', 'on');
  }
}
</script>

<style>

.online1.el1 {
  background:url("../../public/img/on1_1.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
  url("../../public/img/on2_1.png") no-repeat calc(50% - 100px) calc(50% + 50px );
}
.online1.el2 {
  background:url("../../public/img/on1_2.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
  url("../../public/img/on2_2.png") no-repeat calc(50% - 100px) calc(50% + 50px );
}
.online1.el3 {
  background:url("../../public/img/on1_3.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
  url("../../public/img/on2_3.png") no-repeat calc(50% - 100px) calc(50% + 50px );
}
@media screen and (max-width: 767px){
  .online1.el1 {
    background: url("../../public/img/on1_1.png") no-repeat calc(50% - 130px) calc(50% + 40px ),
    url("../../public/img/on1_2.png") no-repeat calc(50% + 100px) calc(50% + 0 )   ;
  }
  .online1.el2 {
    background: url("../../public/img/on1_2.png") no-repeat calc(50% - 130px) calc(50% + 40px ),
    url("../../public/img/on2_2.png") no-repeat calc(50% + 100px) calc(50% + 0 )   ;
  }
  .online1.el3 {
    background: url("../../public/img/on1_3.png") no-repeat calc(50% - 130px) calc(50% + 40px ),
    url("../../public/img/on2_3.png") no-repeat calc(50% + 100px) calc(50% + 0 )   ;
  }
}

</style>
