<template>
<div class="page position-relative">
  <a href="" @click.prevent="slideArrow" class="scroll_link" :class="{'lastScreen': activeSlide === 4}">
    <svg width="31" height="65" viewBox="0 0 31 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 64.77L31 49.77H0L15.5 64.77Z" fill="rgb(84, 226, 9)"/>
      <path d="M15 50V0" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
    </svg>
  </a>
  <div ref="fullpage4" id="fullpage4">
    <div class="section technology1" :class="{'el1': pageCounters.tech === 1, 'el2': pageCounters.tech === 2, 'el3': pageCounters.tech === 3}">
      <div class="container">
        <h1 class="green text-right anim1">
          {{$i18n.t('technology.h1')}}, <br> {{$i18n.t('technology.h1_1')}},
          <span class="d-block text-left anim2"> {{$i18n.t('technology.h1_2')}}</span>

        </h1>
        <ul class="d-flex justify-content-start align-items-start h-100">
          <li class="p-3 mt-5 element anim2"><h2 class="green">
            <a @click.prevent="scrollTo('.technology2',2)" href=""> {{$i18n.t('technology.el1_h')}}</a>
          </h2>
          </li>
          <li class="p-3 mt-5 element mx-5 anim1">
            <h2 class="green">
            <a  @click.prevent="scrollTo('.technology3',3)" href=""> Tap to Phone &  Wallet App</a>
            </h2><h2 class="green pt-0">
            <a  @click.prevent="scrollTo('.technology4',4)" href=""> {{$i18n.t('technology.el7_h')}}</a>
          </h2>
          </li>
        </ul>
      </div>
    </div>
    <div class="section technology2">
      <div class="container">
        <ul class="d-flex justify-content-between h-100">
          <li class="align-self-start element custom2 anim1"><h3>{{$i18n.t('technology.el1_h')}}</h3></li>
          <li class="mx-5 element align-self-end mt-5 mt-md-0 anim2">
            <div>
              <img src="img/icons/arcticons_puzzles.svg" alt="">
            </div>
            <h2 class="green">{{$i18n.t('technology.el3_h')}}
            </h2>
            <p>
              {{$i18n.t('technology.el3_p1')}}</p>
      <p> {{$i18n.t('technology.el3_p2')}}

            </p>
          </li>
          <li class="mx-4 element align-self-start custom3 anim1">
            <div>
              <img src="img/icons/arcticons_smart-transfer.svg" alt="">
            </div>
            <h2 class="green"> {{$i18n.t('technology.el4_h')}}

            </h2>
            <ul>
              <li>{{$i18n.t('technology.el4_p1')}}; </li>
              <li>{{$i18n.t('technology.el4_p2')}}; </li>
              <li>{{$i18n.t('technology.el4_p3')}}; </li>
              <li>{{$i18n.t('technology.el4_p4')}} </li>
            </ul>
          </li>
          <li class="mx-4 element align-self-start  mt-5 mt-md-0  anim2">
            <div>
              <img src="img/icons/arcticons_investing.svg" alt="">
            </div>
            <h2 class="green">{{$i18n.t('technology.el5_h')}}
            </h2>
            <ul>
              <li> {{$i18n.t('technology.el5_p1')}}; </li>
              <li> {{$i18n.t('technology.el5_p2')}}; </li>
              <li> {{$i18n.t('technology.el5_p3')}} </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="section technology3">
      <div class="container">
        <ul class="d-flex justify-content-between h-100">
          <li class="align-self-start element custom2 anim1"><h3>Tap to Phone &  Wallet App</h3></li>
          <li class="mx-4 element align-self-start anim2">
            <div>
              <img src="img/icons/arcticons_walletcount.svg" alt="">
            </div>
            <h2 class="green">{{$i18n.t('technology.el6_h')}}
            </h2>

          </li>
          <li class="mx-4 element align-self-center  custom3 anim1">
            <div class="pb-3">
              <img src="img/icons/arcticons_tickets.svg" alt="">
            </div>
    <p>{{$i18n.t('technology.el6_p1')}}</p>
          </li>
          <li class="mx-4 element align-self-start  custom5 anim2">
            <div class="pb-3">
              <img src="img/icons/arcticons_skydroid.svg" alt="">
            </div>

            <ol>
              <li>{{$i18n.t('technology.el6_p2')}}</li>
              <li> {{$i18n.t('technology.el6_p3')}}</li>
              <li>{{$i18n.t('technology.el6_p4')}} </li>
              <li> {{$i18n.t('technology.el6_p5')}}</li>
              <li> {{$i18n.t('technology.el6_p6')}} </li>

            </ol>
          </li>

        </ul>
      </div>
    </div>
    <div class="section technology4">
      <div class="container">
        <ul class="d-flex justify-content-between h-100">
          <li class="align-self-start element custom2 anim1"><h3>{{$i18n.t('technology.el7_h')}}</h3></li>
          <li class="mx-5 element align-self-md-end align-self-start anim2">
            <div>
              <img src="img/icons/arcticons_locker-2.svg" alt="">
            </div>
            <h2 class="green">{{$i18n.t('technology.el8_h')}}
            </h2>

          </li>
          <li class="mx-4 element align-self-start custom7 anim1">
            <div>
              <img src="img/icons/arcticons_automation.svg" alt="">
            </div>
           <p> {{$i18n.t('technology.el8_p1')}} </p>
          </li>
          <li class="mx-4 element align-self-start mt-5 mt-md-0 anim2">
            <div>
              <img src="img/icons/arcticons_jamesdsp.svg" alt="">
            </div>
          <p>{{$i18n.t('technology.el8_p2')}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {animateAll, scrollTo} from "@/utills/all";

export default {
  name: "Technology",
  props: ['pageCounters'],
  data(){
    return{
      activeSlide: 1,
      blockScroll: false
    }
  },
  methods: {
    scrollTo,
    animateAll,
    slide(){
      const target =  '.technology' + ( this.activeSlide===4 ? 1 : this.activeSlide+1)
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
      });
      this.activeSlide = this.activeSlide === 4 ? 1 : this.activeSlide+1
    },
    slideArrow(){
      if (!this.blockScroll){
        this.blockScroll= true;
        this.slide();
        this.animateAll(`.technology${this.activeSlide}`);
        this.$emit('slide',this.activeSlide);
      }
      setTimeout(()=>{
        this.blockScroll = false;
      },1500)
    },
  },
  created() {
    this.$emit('slide',this.activeSlide);
  },
  mounted() {
    this.animateAll(`.technology${this.activeSlide}`);
    const startBlock = this.$route.params.code ? `.technology${this.$route.params.code}` : '.technology1';
    this.activeSlide = this.$route.params.code ? +this.$route.params.code : 1;
    document.querySelector(startBlock).scrollIntoView({
      behavior: 'smooth'
    });
    if (window.innerWidth > 1239){
      document.addEventListener('wheel',e=>{
        if (e.deltaY>=0 && this.activeSlide === 4 )   return;
        if (e.deltaY<=0 && this.activeSlide === 1 )   return;
        if (!this.blockScroll){
          this.blockScroll = true;
          if (e.deltaY>0 && this.activeSlide!==4){
            this.slide();
            this.animateAll(`.technology${this.activeSlide}`);
          }
          if ( e.deltaY<0 &&this.activeSlide !==1) {

            document.querySelector(`.technology${this.activeSlide - 1}`).scrollIntoView({
              behavior: 'smooth'
            });
            this.activeSlide =  this.activeSlide-1;
            this.animateAll(`.technology${this.activeSlide}`);
          }

          this.$emit('slide',this.activeSlide);
          setTimeout(()=>{
            this.blockScroll = false;
          },1500)
        }
      })
    }

  },
  beforeUnmount() {
    this.$emit('count', 'tech');
  }
}
</script>

<style scoped>
.technology1.el1 {
  background: url("../../public/img/techBg1_1.png") no-repeat calc(50% - 100px) calc(50% - 50px ),
  url("../../public/img/techBg2_1.png") no-repeat calc(50% + 100px) calc(50% + 150px );
}
.technology1.el2 {
  background: url("../../public/img/techBg1_2.png") no-repeat calc(50% - 100px) calc(50% - 50px ),
  url("../../public/img/techBg2_2.png") no-repeat calc(50% + 100px) calc(50% + 150px );
}
.technology1.el3 {
  background: url("../../public/img/techBg1_3.png") no-repeat calc(50% - 100px) calc(50% - 50px ),
  url("../../public/img/techBg2_3.png") no-repeat calc(50% + 100px) calc(50% + 150px );
}
.technology2 ul li.element:nth-child(1){
  width: 300px;
  margin-right: 50px;
}
@media screen and (max-width: 767px){
  .technology1.el1 {
    background: url("../../public/img/techBg1_1.png") no-repeat calc(50% - 170px) calc(50% + 20px ),
    url("../../public/img/techBg2_1.png") no-repeat calc(50% + 170px) calc(50% - 20px );
  }
  .technology1.el2 {
    background: url("../../public/img/techBg1_2.png") no-repeat calc(50% - 170px) calc(50% + 20px ),
    url("../../public/img/techBg2_2.png") no-repeat calc(50% + 170px) calc(50% - 20px );
  }
  .technology1.el3 {
    background: url("../../public/img/techBg1_3.png") no-repeat calc(50% - 170px) calc(50% + 20px ),
    url("../../public/img/techBg2_3.png") no-repeat calc(50% + 170px) calc(50% - 20px );
  }

}

</style>
