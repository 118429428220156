<template>
  <div class="page position-relative ">
    <a href="" @click.prevent="slideArrow" class="scroll_link" :class="{'lastScreen': activeSlide === 2}">
      <svg width="31" height="65" viewBox="0 0 31 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5 64.77L31 49.77H0L15.5 64.77Z" fill="rgb(84, 226, 9)"/>
        <path d="M15 50V0" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
      </svg>
    </a>
    <div ref="fullpage3" id="fullpage3">
      <div class="section offline1" :class="{'el1': pageCounters.off === 1, 'el2': pageCounters.off === 2, 'el3': pageCounters.off === 3}">
        <div class="container">
          <h1 class="green text-right anim1">
            {{ $i18n.t('offline.h1') }} <br> {{ $i18n.t('offline.h1_1') }}
            <span class="d-block text-left anim2"> {{ $i18n.t('offline.h1_2') }} </span>

          </h1>
          <ul class="d-flex justify-content-md-start justify-content-end align-items-start h-100">
            <li class="p-4 mt-5 element anim1"><h2 class="green">
              <a @click.prevent="slide" href=""> {{ $i18n.t('offline.el1_h') }}</a>
            </h2>
            </li>
          </ul>
        </div>
      </div>
      <div class="section offline2">
        <div class="container">

          <ul class="d-flex justify-content-between h-100">
            <li class="align-self-start element custom2 anim1"><h3>{{ $i18n.t('offline.el1_h') }}</h3></li>
            <li class="mx-4 element align-self-md-end align-self-start  anim2">
              <div>
                <img src="img/icons/arcticons_wifianalyzer.svg" alt="">
              </div>
              <h2 class="green">
                {{ $i18n.t('offline.el2_h') }}
              </h2>
              <p>{{ $i18n.t('offline.el2_p1') }}</p>

              <p>{{ $i18n.t('offline.el2_p2') }}</p>
            </li>
            <li class="mx-4 element align-self-start custom3 anim1">
              <div>
                <img src="img/icons/arcticons_lineage-updater.svg" alt="">
              </div>
              <h2 class="green">
                {{ $i18n.t('offline.el3_h') }}
              </h2>
<p>{{ $i18n.t('offline.el3_p1') }}</p>
              <ul>

                <li>{{ $i18n.t('offline.el3_p2') }}</li>
                <li>{{ $i18n.t('offline.el3_p3') }}</li>
                <li>{{ $i18n.t('offline.el3_p4') }}</li>
              </ul>


            </li>
            <li class="mx-4 element align-self-md-center align-self-start  anim2">
              <div>
                <img src="img/icons/arcticons_homewav.svg" alt="">
              </div>
              <h2 class="green">
                {{ $i18n.t('offline.el4_h') }}

              </h2>
              <p>
                {{ $i18n.t('offline.el4_p1') }}</p>

               <p>  {{ $i18n.t('offline.el4_p2') }}</p>

             <ul>
               <li>  {{ $i18n.t('offline.el4_p3') }}</li>
               <li>  {{ $i18n.t('offline.el4_p4') }}</li>
               <li>  {{ $i18n.t('offline.el4_p5') }}</li>
             </ul>

              <div><router-link :to="{name: 'TechnologyBlock', params: {code: '3'}}"><bounce/></router-link></div>
            </li>
            <li class="mx-4 element align-self-start custom3 anim1">
              <div>
                <img src="img/icons/arcticons_way.svg" alt="">
              </div>
              <h2 class="green">
                {{$i18n.t('offline.el5_h')}}

              </h2>
              <p>  {{$i18n.t('offline.el5_p1')}}</p>
              <p>{{$i18n.t('offline.el5_p2')}}</p>
<ul>
  <li> {{$i18n.t('offline.el5_p3')}}</li>
  <li>{{$i18n.t('offline.el5_p4')}} </li>
  <li> {{$i18n.t('offline.el5_p5')}}</li>
  <li>{{$i18n.t('offline.el5_p6')}}</li>
</ul>


            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bounce from "@/components/bounceEllipse";
import {animateAll} from "@/utills/all";

export default {
  name: "Offline",
  props: ['pageCounters'],
  components:{
    Bounce
  },
  data(){
    return{
      activeSlide: 1,
      blockScroll: false
    }
  },
  methods: {
    animateAll,
    slide(){
      const target = this.activeSlide === 1 ? '.offline2' : '.offline1'
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
      });
      this.activeSlide = this.activeSlide === 1 ? 2 : 1;
      this.animateAll(`.offline${this.activeSlide}`);
    },
    slideArrow(){
      if (!this.blockScroll){
        this.blockScroll= true;
        this.slide();
      }
      this.$emit('slide',this.activeSlide);
      setTimeout(()=>{
        this.blockScroll = false;
      },1500)
    },
  },
  created() {
    this.$emit('slide',this.activeSlide);
  },
  mounted() {
    this.animateAll(`.offline${this.activeSlide}`);
    document.querySelector('.offline1').scrollIntoView({
      behavior: 'smooth'
    });
    if (window.innerWidth > 1239){
      document.addEventListener('wheel',e=>{
        if (e.deltaY>=0 && this.activeSlide === 2 )   return;
        if (e.deltaY<=0 && this.activeSlide === 1 )   return;
        if (!this.blockScroll){
          this.blockScroll = true;
          this.slide();
          this.$emit('slide',this.activeSlide);
          setTimeout(()=>{
            this.blockScroll = false;
          },1500)
        }
      })
    }

  },
  beforeUnmount() {
    this.$emit('count', 'off');
  }
}
</script>

<style>
.offline1.el1 {
  background:  url("../../public/img/off1_1.png") no-repeat calc(50% + 100px) calc(50% + 180px ),
 url("../../public/img/off2_1.png") no-repeat calc(50% - 100px) calc(50% - 0px )  ;
}
.offline1.el2 {
  background:  url("../../public/img/off1_2.png") no-repeat calc(50% + 100px) calc(50% + 180px ),
 url("../../public/img/off2_2.png") no-repeat calc(50% - 100px) calc(50% - 0px )  ;
}
.offline1.el3 {
  background:  url("../../public/img/off1_3.png") no-repeat calc(50% + 100px) calc(50% + 180px ),
 url("../../public/img/off2_3.png") no-repeat calc(50% - 100px) calc(50% - 0px )  ;
}
.offline2 .element{
  width: 200px;
}
@media screen and (max-width: 767px){
  .offline1.el1 {
    background:
    url("../../public/img/off1_1.png") no-repeat calc(50% + 100px) calc(50% + 135px ),
    url("../../public/img/off1_2.png") no-repeat calc(50% - 180px) calc(50% - 30px );
  }
  .offline1.el2 {
    background:
    url("../../public/img/off1_2.png") no-repeat calc(50% + 100px) calc(50% + 135px ),
    url("../../public/img/off2_2.png") no-repeat calc(50% - 180px) calc(50% - 30px );
  }
  .offline1.el3 {
    background:
    url("../../public/img/off1_3.png") no-repeat calc(50% + 100px) calc(50% + 135px ),
    url("../../public/img/off2_3.png") no-repeat calc(50% - 180px) calc(50% - 30px );
  }
}
</style>
