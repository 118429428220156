<template>
 <div class="page position-relative">
   <a href="" @click.prevent="slideArrow" class="scroll_link" :class="{'lastScreen': activeSlide === 2}">
     <svg width="31" height="65" viewBox="0 0 31 65" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M15.5 64.77L31 49.77H0L15.5 64.77Z" fill="rgb(84, 226, 9)"/>
       <path d="M15 50V0" stroke="rgb(84, 226, 9)" stroke-miterlimit="10"/>
     </svg>
   </a>
   <div ref="fullpage1" id="fullpage1">
   <div class="section home1" :class="{'el1': pageCounters.home === 1, 'el2': pageCounters.home === 2, 'el3': pageCounters.home === 3}">
    <div class="container position-relative">
      <h1 class="green anim1 text-right">
        {{ $i18n.t('home.h1') }}
        <span class="d-block text-left anim2">{{ $i18n.t('home.h1_1') }}</span>
      </h1>
      <ul class="d-flex justify-content-end align-items-start">
        <li class="mt-xl-5 mt-4 element anim1 text-end me-5"><h2 class="green "> {{ $i18n.t('home.el1_h') }}</h2>

          <p>{{ $i18n.t('home.el1_p1') }}</p>
          <p>  {{ $i18n.t('home.el1_p2') }} </p>
          <p>  {{ $i18n.t('home.el1_p3') }} </p>
          <p>  {{ $i18n.t('home.el1_p4') }}</p></li>
        <li class=" element anim2">
          <h2 class="green"><br class="d-none d-xl-inline" v-if="$i18n.locale === 'en'"> {{ $i18n.t('home.el2_h') }}</h2>
          <p>{{ $i18n.t('home.el2_p1') }} </p>
          <p>  {{ $i18n.t('home.el2_p2') }} </p>
          <p>  {{ $i18n.t('home.el2_p3') }}</p>
          <p>  {{ $i18n.t('home.el2_p4') }}</p>
        </li>
      </ul>
    </div>

   </div>
   <div class="section home2">
     <div class="container">
       <ul class="d-flex  justify-content-lg-center justify-content-start align-items-center h-100">
         <li class=" element align-self-start custom2 anim1">
           <h3>{{ $i18n.t('home.el6_h') }}</h3>
         </li>
         <li class="mx-4 element align-self-md-center align-self-start mt-5 anim2">
           <div>
             <img src="img/icons/arcticons_swift-backup.svg" alt="">
           </div>
           <h2 class="green">
             {{ $i18n.t('home.el3_h') }}
           </h2>
           <p>{{ $i18n.t('home.el3_p1') }}
             </p>
           <p> {{ $i18n.t('home.el3_p2') }}</p>
           <p>
             {{ $i18n.t('home.el3_p3') }}</p>
           <p>
             {{ $i18n.t('home.el3_p4') }}</p>
           <div><router-link :to="{name: 'Online'}"><bounce/></router-link></div>
         </li>
         <li class="mx-4 element align-self-start custom6 anim1">
           <div>
             <img src="img/icons/arcticons_skydroid.svg" alt="">
           </div>
           <h2 class="green">
             {{ $i18n.t('home.el4_h') }}
           </h2>
           <p>{{ $i18n.t('home.el4_p1') }}</p>

           <p> {{ $i18n.t('home.el4_p2') }}</p>

           <p>{{ $i18n.t('home.el4_p3') }}</p>
           <div><router-link :to="{name: 'Offline'}"><bounce/></router-link></div>
         </li>
         <li class="mx-4 element align-self-center mt-md-0 mt-5 anim2">
           <div>
             <img src="img/icons/arcticons_puzzles.svg" alt="">
           </div>
           <h2 class="green">
             {{ $i18n.t('home.el5_h') }}
           </h2>
           <p>{{ $i18n.t('home.el5_p1') }}</p>

           <p> {{ $i18n.t('home.el5_p2') }}</p>

           <p> {{ $i18n.t('home.el5_p3') }}</p>
           <div><router-link :to="{name: 'Technology'}"><bounce/></router-link></div>
         </li>
       </ul>
     </div>
   </div>
 </div>
 </div>
</template>

<script>
import Bounce from "@/components/bounceEllipse";
import {animateAll} from "@/utills/all";
export default {
  name: 'Home',
  props: ['pageCounters'],
  components:{
    Bounce
  },
  data() {
    return {
      activeSlide: 1,
      blockScroll: false
    }
  },
  computed: {
  },
  methods: {
    animateAll,
    slideArrow(){
      if (!this.blockScroll){
        this.blockScroll= true;
        this.slide();
        this.$emit('slide',this.activeSlide);
        setTimeout(()=>{
          this.blockScroll = false;
        },1500)
      }
    },
    slide(){
      const target = this.activeSlide === 1 ? '.home2' : '.home1';
      document.querySelector(target).scrollIntoView({
        behavior: 'smooth'
      });
      this.activeSlide = this.activeSlide === 1 ? 2 : 1;
     this.animateAll(`.home${this.activeSlide}`);
    }
  },
  created() {
    this.$emit('slide',this.activeSlide);
  },
  mounted() {
    this.animateAll(`.home${this.activeSlide}`);
    document.querySelector('.home1').scrollIntoView({
      behavior: 'smooth'
    });
    if (window.innerWidth > 1239){
      document.addEventListener('wheel',e=>{
        if (e.deltaY>=0 && this.activeSlide === 2 )   return;
        if (e.deltaY<=0 && this.activeSlide === 1 )   return;
        if (!this.blockScroll){
          this.blockScroll = true;
          this.slide();
          this.$emit('slide',this.activeSlide);
          setTimeout(()=>{
            this.blockScroll = false;
          },1500)
        }
      })
    }

  },
  beforeUnmount() {
    this.$emit('count', 'home');
  }
}
</script>
<style>
.home1.el1 {
  background: url("../../public/img/home2_1.png") no-repeat calc(50% + 100px) calc(50% - 100px ),
  url("../../public/img/home1_1.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
}
.home1.el2 {
  background: url("../../public/img/home2_2.png") no-repeat calc(50% + 100px) calc(50% - 100px ),
  url("../../public/img/home1_2.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
}
.home1.el3 {
  background: url("../../public/img/home2_3.png") no-repeat calc(50% + 100px) calc(50% - 100px ),
  url("../../public/img/home1_3.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
}
.home1 ul{
  margin-top: -50px;
}
.home2 ul .element:nth-child(1){
     width: 325px;
   }
@media screen and (max-width: 767px){
  .home1.el1 {
    background: url("../../public/img/home2_1.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
    url("../../public/img/home2_1.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
  }
  .home1.el2 {
    background: url("../../public/img/home2_2.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
    url("../../public/img/home2_2.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
  }
  .home1.el3 {
    background: url("../../public/img/home2_3.png") no-repeat calc(50% + 100px) calc(50% - 50px ),
    url("../../public/img/home2_3.png")no-repeat  calc(50% - 100px) calc(50% - 0px );
  }
  .home1 ul{
    margin-top: 0;
  }

}
</style>
